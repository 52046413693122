.package-viewer rect {
  fill: #fff;
  stroke: #333;
}

.package-viewer path {
  stroke: #333;
  fill: #333;
  stroke-dasharray: 5, 5;
  stroke-width: 1px;
}

.package-viewer .module rect {
  fill: #fff;
}

.package-viewer .soup rect {
  fill: gainsboro;
}

.package-viewer .builtin rect {
  fill: whitesmoke;
}

.package-viewer .unknown rect {
  stroke-dasharray: 6, 3;
  stroke-width: 1px;
}

.package {
  cursor: pointer;
}
